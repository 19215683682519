import {
    SET_BID_AUCTION,
    SET_AUCTIONS,
    SET_AUCTION,
    AuctionState,
} from '../actions/AuctionActions'

const initialState = {
    auctions: [],
    auction: {}
} as AuctionState

export const AuctionReducer = (state = initialState, action: any) : AuctionState => {
    switch (action.type) {
        case SET_AUCTIONS:
            return {
                ...state,
                auctions: action.data.auctions,
            }
        case SET_AUCTION:
            return {
                ...state,
                auction: action.data.auction,
            }
        case SET_BID_AUCTION:
            return {
                ...state,
                auction: {
                    ...state.auction,
                    products: action.data
                },
            }
        default:
            return state
    }
}
