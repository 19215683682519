import {Link} from 'react-router-dom'
import {Skeleton} from "@mui/material";
import {getNoun} from "../../../utils/getNoun"
import {
    AuctionListSerializer,
} from "../../../store/actions/AuctionActions";
import {TDXButton} from "../Buttons";
import {useEffect, useState} from "react";

interface TDXAuctionInterface {
    auction?: AuctionListSerializer,
    loading?: boolean
}

interface ButtonData {
    color?: string,
    text?: string
}

export default function TDXAuction ({auction = null, loading=false}: TDXAuctionInterface) {
    const [dateFinishText, setDateFinishText] = useState<string>('')
    const [secondsDateFinish, setSecondsDateFinish] = useState<number>(0)
    const [isFinish, setFinish] = useState(false)
    const [buttonData, setButtonData] = useState<ButtonData>({color: '', text: ''})

    useEffect(() => {
        if (secondsDateFinish > 0) {
            const seconds = secondsDateFinish - 1;

            setTimeout(setSecondsDateFinish, 1000, seconds);

            const days = Math.floor(seconds / (3600*24));
            const hours = Math.floor(seconds % (3600*24) / 3600);

            setDateFinishText(days+' '+getNoun(days, 'день', 'дня', 'дней')+' : '+hours+' '+getNoun(hours, 'час', 'часа', 'часов'))
        } else if (secondsDateFinish === 0) {
            setFinish(true)
            setDateFinishText('Аукцион завершен')
        }
    }, [auction, secondsDateFinish]);

    useEffect(() => {
        if (!auction?.active) {
            setFinish(true)
            setSecondsDateFinish(0)
        } else if (auction?.date_finish){
            const seconds = Math.floor(auction?.date_finish - Date.now()/1000)
            setFinish(!(seconds > 0))
            setSecondsDateFinish(seconds)
        }

        const data: ButtonData = {color: '', text: ''}

        if (typeof(auction?.last_bid?.my_price) === 'number' && auction?.last_bid?.my_price > 0) {
            if (auction?.last_bid?.is_my_bid) {
                data.color = 'green'
                data.text = 'Ваша ставка в игре'
            } else {
                data.color = 'gray'
                data.text = 'Ваша ставка перебита'
            }
        } else {
            data.color = 'black'
            data.text = 'Участвовать'
        }

        setButtonData(data)
    }, [auction])

    return !loading && auction?.id ? (
        <div className={'auction-item'}>
            <div className={'auction-item__column'}>
                <Link to={`/auctions/${auction.id}`} className={'auction-item__title'}>Лот №{auction.id}</Link>
            </div>
            <div className={'auction-item__column'}>
                <div className={'auction-item__products'}>
                    <span className={'auction-item__products-title'}>Состав лота:</span>
                    <div className={'auction-item__products-list'}>
                        {auction?.products.map((v,k) => (
                            <div className={'auction-item__product'} key={k}>
                                {k+1}. {v?.name} - {v?.quantity ?? 0} шт.
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={'auction-item__column'}>
                <div className={'auction-item__prices'}>
                    {typeof(auction?.last_bid?.my_price) === 'number' && auction?.last_bid?.my_price > 0 && (
                        <div className={'auction-item__prices-row color-green'}>
                            <span>Ваша ставка:</span>
                            <span>{auction?.last_bid?.my_price} {auction?.currency}</span>
                        </div>
                    )}
                    <div className={'auction-item__prices-row'}>
                        <span>Начальная цена:</span>
                        <span>{auction?.price} {auction?.currency}</span>
                    </div>
                    {typeof(auction?.last_bid?.price) === 'number' && auction?.last_bid?.price > 0 && (
                        <div className={`auction-item__prices-row ${typeof(auction?.last_bid?.my_price) === 'number' && typeof(auction?.last_bid?.price) === 'number' && auction?.last_bid?.my_price > 0 && auction?.last_bid?.my_price < auction?.last_bid?.price ? 'color-red': ''}`}>
                            <span>Текущая ставка:</span>
                            <span>{auction?.last_bid?.price} {auction?.currency}</span>
                        </div>
                    )}
                </div>
            </div>
            <div className={'auction-item__column'}>
                <div className={'auction-item__actions'}>
                    {!isFinish && (
                        <TDXButton
                            href={`/auctions/${auction.id}`}
                            size={'medium'}
                            bg={buttonData.color}
                        >{buttonData.text}</TDXButton>
                    )}
                    <div className={'auction-item__times'}>
                        {!isFinish && (<span>До конца аукциона:</span>)}
                        <span>{dateFinishText}</span>
                    </div>
                </div>
            </div>
        </div>
    ): (
        <div className={'auction-item'}>
            <div className={'auction-item__column'}>
                <Skeleton className={'auction-item__title'} height={'100%'} />
            </div>
            <div className={'auction-item__column'}>
                <div className={'auction-item__products'}>
                    <Skeleton className={'auction-item__products-title'} height={'100%'} />
                    <div className={'auction-item__products-list'}>
                        <Skeleton className={'auction-item__product'} height={'100%'} />
                        <Skeleton className={'auction-item__product'} height={'100%'} />
                    </div>
                </div>
            </div>
            <div className={'auction-item__column'}>
                <div className={'auction-item__prices'}>
                    <Skeleton className={'auction-item__prices-row'} height={'100%'} />
                    <Skeleton className={'auction-item__prices-row'} height={'100%'} />
                    <Skeleton className={'auction-item__prices-row'} height={'100%'} />
                </div>
            </div>
            <div className={'auction-item__column'}>
                <Skeleton className={'auction-item__actions'} height={'100%'} />
            </div>
        </div>
    )
}
