import {TDXNavLink, TDXWaitList} from "../UI";
import TDXBufferLink from "../Buffer/TDXBufferLink";
import HeaderLinkMenu from "./HeaderLinkMenu";
import {Skeleton} from "@mui/material";
import {useState, useEffect} from 'react'

import {useAppSelector} from "../../shared/hooks/hooks";
import HeaderCatalogCategories from "./HeaderCatalogCategories";

const HeaderMenu = () => {
    const {name, /*rates, */isManager, isSpecial,
        isCustomPrice, region, isMegaSklad, isElektronmir} = useAppSelector(state => state.Profile.profile)
    //const [bufferIsActive, setBufferIsActive] = useState(false)
    const [manualPage, setManualPage] = useState('/page/manual')
    const hasRefusals = false
    const hasSupplies = false

    useEffect(() => {
        if (isMegaSklad) {
            setManualPage('/page/manual-megasklad')
        } else if (isElektronmir) {
            setManualPage('/page/manual-elektronmir')
        }
    },[isMegaSklad, isElektronmir])

    return (
        <div className="links-container">
            {name ? (
                <>
                    <HeaderCatalogCategories />
                    <TDXNavLink to="/user/orders" title="Заказы" />
                    <TDXBufferLink />
                    {!isSpecial? <TDXNavLink to="/user/matching" title="Отчет" /> : ''}
                    {hasRefusals ? <TDXNavLink to="/user/refusals" title="Отказы" /> : ''}
                    <TDXWaitList />
                    <TDXNavLink to="/auctions" title="Аукцион" className={'new-icon'} end />
                    {
                        (hasSupplies && isManager)
                        ? <TDXNavLink to="/user/supplies" title="Поставки" /> : ''
                    }
                    {!isMegaSklad && !isCustomPrice ? <TDXNavLink to="/page/delivery-schedule" title="График доставок" />: ''}
                    {!isSpecial ? <HeaderLinkMenu title="FAQ">
                        <TDXNavLink to={`${manualPage}`} title="Инструкция пользователя" />
                        <TDXNavLink to={!isCustomPrice ? `/page/apidoc`: `/page/apidoc-rf`} title="Документация по API" />
                        {!isMegaSklad ? (
                            <TDXNavLink to="/news" title="Новости компании" />
                        ):''}
                    </HeaderLinkMenu> : ''}

                    {isManager && (
                        <HeaderLinkMenu title="Менеджер">
                            <TDXNavLink
                                to="/manager/users-settings"
                                title="Настройки пользователей"
                            />
                        </HeaderLinkMenu>
                    )}
                </>
            ) : (
                <>
                    <Skeleton className="tdx-nav-link link" width={105} height={30} />
                    <Skeleton className="tdx-nav-link link" width={105} height={30} />
                    <Skeleton className="tdx-nav-link link" width={105} height={30} />
                    <Skeleton className="tdx-nav-link link" width={105} height={30} />
                </>
            )}
        </div>
    ) }

export default HeaderMenu
