import {get, post} from './api'

const AuctionService = {
    getAuctionList: () => get('/auctions/'),
    getAuction: (id?: string) => get(`/auctions/${id ? `${id}` : ''}`),
    setBid: (id: string, body: Record<string, any>) => post(`/auctions/${id}/set_bid`, body),
}

export const AuctionRequestsEnum = {
    getAuctionList: '/auctions/',
    getAuction: (id: string) => `/auctions/${id}`
}

export default AuctionService
